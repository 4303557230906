import React, { ReactNode, createContext, useState, useCallback, useEffect } from "react";
import { getScopeIdOrThrow } from "@telia/b2b-customer-scope";
import { UserDataType } from "../_types/UserDataType";

const UserDataContext = createContext<UserDataType | null>(null);

const UserScopeProvider = ({ children }: { children: ReactNode }) => {
  const [scopeId, setScopeId] = useState<string>();

  const fetchScopeId = useCallback(async () => {
    try {
      getScopeIdOrThrow().then((_scopeId) => {
        setScopeId(_scopeId);
      });
    } catch (error) {
      setScopeId(undefined);
    }
  }, []);

  useEffect(() => {
    fetchScopeId();
  }, [fetchScopeId]);

  return (
    <UserDataContext.Provider value={{ scopeId: scopeId }}>{children}</UserDataContext.Provider>
  );
};

export { UserScopeProvider, UserDataContext };
