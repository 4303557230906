import { PageReadableKey } from "./page-readable-key";

const resource = PageReadableKey.IOT_ALERTS;

const translationKeys = {
  headerInfo: {
    alerts: `${resource}.header.alerts`,
    overview: `${resource}.header.overview`,
  },
  agreementSelect: {
    label: `${resource}.agreement.select.label`,
  },
  buttons: {
    createNew: `${resource}.buttons.createNew`,
  },
  table: {
    emptyHeading: `${resource}.table.empty.label`,
    exportButton: { label: `${resource}.table.export.button` },
    exportLine: { label: `${resource}.table.export.toFile` },
    filters: {
      subscriptionSearch: `${resource}.table.filters.search`,
    },
  },
  alertsTable: {
    columns: {
      subscription: `${resource}.alerts.columns.subscription`,
      subscriptionType: `${resource}.alerts.columns.subscriptionType`,
      sms: `${resource}.alerts.columns.sms`,
      mms: `${resource}.alerts.columns.mms`,
      speech: `${resource}.alerts.columns.speech`,
      csdata: `${resource}.alerts.columns.csdata`,
      gprs: `${resource}.alerts.columns.gprs`,
      interval: `${resource}.alerts.columns.interval`,
      validFrom: `${resource}.alerts.columns.validFrom`,
    },
    subscriptionType: {
      AGREEMENT: `${resource}.alertsTable.agreement`,
      SUBSCRIPTION: `${resource}.alertsTable.subscription`,
    },
  },
  errors: {
    agreementError: `${resource}.agreement.error`,
    dataError: `${resource}.data.error.heading`,
  },
  months: {
    M1: `${resource}.months.1`,
    M2: `${resource}.months.2`,
    M3: `${resource}.months.3`,
    M4: `${resource}.months.4`,
    M5: `${resource}.months.5`,
    M6: `${resource}.months.6`,
    M7: `${resource}.months.7`,
    M8: `${resource}.months.8`,
    M9: `${resource}.months.9`,
    M10: `${resource}.months.10`,
    M11: `${resource}.months.11`,
    M12: `${resource}.months.12`,
  },
};

export default translationKeys;
