import * as React from "react";
import {
  Heading,
  Paragraph,
  RadioButtonGroup,
  Select,
  TextField,
  Grid,
  Button,
} from "@purpurds/purpur";
import { t } from "@telia/b2b-microcopy-translation";

import translationKeys from "../../translation/translation-keys";

//const { headerInfo } = translationKeys;

import styles from "./CreateAlert.module.scss";
import { useState } from "react";
import { AgreementsType } from "../../_types/UserDataType";

interface Props {
  agreements: AgreementsType[];
}

interface RadioButton {
  id: string;
  label: string;
  value: string;
}

interface Option {
  label: string;
  value: string;
}

interface Settings {
  type: string;
  agreement: string;
  interval: string;
  sms: number;
  mms: number;
  gprs: number;
  gprsUnit: string;
  speech: number;
}

const CreateAlert = (props: Props) => {
  const { agreements } = props;

  const alarmChoices: RadioButton[] = [
    {
      id: "1",
      label: "Agreement",
      value: "agreement",
    },
    {
      id: "2",
      label: "Subscription",
      value: "subscription",
    },
  ];

  const intervals = [
    {
      label: "Day",
      value: "day",
    },
    {
      label: "Week",
      value: "week",
    },
    {
      label: "Month",
      value: "month",
    },
    {
      label: "Unknown",
      value: "unknown",
    },
  ];

  const units = [
    {
      label: "KB",
      value: "kb",
    },
    {
      label: "MB",
      value: "mb",
    },
    {
      label: "GB",
      value: "gb",
    },
  ];

  const [type, setType] = useState<string>("agreement");
  const [agreement, setAgreement] = useState<string>();
  const [interval, setInterval] = useState<string>();
  const [sms, setSms] = useState<number>(0);
  const [mms, setMms] = useState<number>(0);
  const [gprs, setGprs] = useState<number>(0);
  const [gprsUnit, setGprsUnit] = useState<string>("kb");
  const [speech, setSpeech] = useState<number>(0);
  const [email, setEmail] = useState<string>();
  const [mobileNumber, setMobileNumber] = useState<string>();

  const closePage = () => {};

  return (
    <form>
      <Grid className={styles.configurationCardRoot}>
        <fieldset className={styles.configurationCard}>
          <Heading tag="h2" variant="title-300">
            Create Alert
          </Heading>
          <div className={styles.configurationCardContent}>
            <Grid>
              <Grid.Item colSpanLg={12}>
                <RadioButtonGroup
                  id="radio-button-story"
                  items={alarmChoices}
                  label="Type of alarm"
                  orientation="horizontal"
                  onValueChange={(value) => {
                    setType(value);
                  }}
                  value={type}
                />
              </Grid.Item>
              <Grid.Item colSpanLg={12}>
                <Select
                  helperText="Pick an agreement"
                  label="Agreements"
                  onChange={(event) => {
                    setAgreement(event.target.value);
                  }}
                  options={agreements}
                  placeholder={{
                    label: "Agreement",
                    value: "",
                  }}
                  value={agreement}
                />
              </Grid.Item>
              <Grid.Item colSpanLg={12}>
                <Select
                  helperText="Pick an interval"
                  label="Choose interval"
                  onChange={(event) => {
                    setInterval(event.target.value);
                  }}
                  options={intervals}
                  placeholder={{
                    label: "Choose an interval",
                    value: "",
                  }}
                  value={interval}
                />
              </Grid.Item>
              <Grid.Item colSpanLg={12}>
                <TextField
                  helperText="SMS amount"
                  id="sms-input"
                  label="SMS"
                  onChange={(event) => {
                    setSms(parseInt(event.target.value));
                  }}
                  placeholder="Enter text"
                  type="number"
                  value={sms}
                />
              </Grid.Item>
              <Grid.Item colSpanLg={12}>
                <TextField
                  helperText="MMS amount"
                  id="mms-input"
                  label="MMS"
                  onChange={(event) => {
                    setMms(parseInt(event.target.value));
                  }}
                  placeholder="Enter text"
                  type="number"
                  value={mms}
                />
              </Grid.Item>
              <Grid.Item colSpanLg={10}>
                <TextField
                  helperText="GPRS amount"
                  id="gprs-input"
                  label="GPRS"
                  onChange={(event) => {
                    setGprs(parseInt(event.target.value));
                  }}
                  placeholder="Enter text"
                  type="number"
                  value={gprs}
                />
              </Grid.Item>
              <Grid.Item colSpanLg={2}>
                <Select
                  helperText="Unit"
                  label="Unit"
                  onChange={(event) => {
                    setGprsUnit(event.target.value);
                  }}
                  options={units}
                  placeholder={{
                    label: "Pick a unit",
                    value: "",
                  }}
                  value={gprsUnit}
                />
              </Grid.Item>
              <Grid.Item colSpanLg={12}>
                <TextField
                  helperText="Speech amount"
                  id="speech-input"
                  label="Speech(minutes)"
                  onChange={(event) => {
                    setSpeech(parseInt(event.target.value));
                  }}
                  placeholder="Enter text"
                  type="number"
                  value={speech}
                />
              </Grid.Item>
              <Grid.Item colSpanLg={12}>
                <Heading tag="h3" variant="subsection-100">
                  Giltig till
                </Heading>
                <Paragraph>2024-12-12</Paragraph>
              </Grid.Item>
            </Grid>
          </div>
        </fieldset>
        <fieldset className={styles.configurationCard}>
          <Heading tag="h2" variant="title-300">
            Larmaviseringar
          </Heading>
          <Paragraph>Fyll i kontaktuppgifter till den som blir aviserad vid larm.</Paragraph>
          <Grid className={styles.configurationCardContent}>
            <TextField
              helperText="Email"
              id="email-input"
              label="Email"
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              placeholder="Enter text"
              type="text"
              value={email}
            />
            <TextField
              helperText="Mobile number"
              id="mobile-input"
              label="Mobile number"
              onChange={(event) => {
                setMobileNumber(event.target.value);
              }}
              placeholder="Enter text"
              type="text"
              value={mobileNumber}
            />
          </Grid>
        </fieldset>
        <div className={styles.formButtons}>
          <Button fullWidth variant="primary">
            Save
          </Button>
          <Button fullWidth variant="secondary" onClick={closePage}>
            Cancel
          </Button>
        </div>
      </Grid>
    </form>
  );
};

export default CreateAlert;
