import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import CreateAlertRoot from "./components/CreateAlertRoot/CreateAlertRoot";
import { TranslationLoader } from "@telia/b2b-microcopy-translation";
import { PageReadableKey } from "./translation/page-readable-key";
import { UserScopeProvider } from "./providers/UserDataContext";
import "@purpurds/purpur/styles";
import "@telia/b2b-layout";
import "@telia/b2x-table";
import "@telia/b2x-paginator";

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      "b2x-teaser-cluster": unknown;
      "b2x-slider": unknown;
    }
  }
}

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  // Remove or set to createRoot when upgrading to React 18
  renderType: "render",
  rootComponent: () => (
    <UserScopeProvider>
      <TranslationLoader resourceSetKeys={[PageReadableKey.IOT_ALERTS]}>
        <CreateAlertRoot />
      </TranslationLoader>
    </UserScopeProvider>
  ),
});

export const { bootstrap, mount, unmount } = lifecycles;
