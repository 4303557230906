import React, { useState } from "react";
import { Button, Grid, Tabs } from "@purpurds/purpur";
import { B2bLayout } from "@telia/b2b-layout/react-cjs";
import { t } from "@telia/b2b-microcopy-translation";

import translationKeys from "../../translation/translation-keys";

import CreateAlert from "../CreateAlert/CreateAlert";

const { headerInfo, buttons } = translationKeys;

type PageType = "configurations" | "new-configuration";

const CreateAlertRoot = () => {
  return (
    <B2bLayout
      heading={t(headerInfo.alerts)}
      pageTitle={t(headerInfo.alerts)}
      hasTabs
      grayBackground
      data-testid="iot-alerts-create-root"
    >
      <CreateAlert agreements={[]} />
    </B2bLayout>
  );
};

export default CreateAlertRoot;
